<template>
  <div id="generated">
    <loading :active="loading || !organizacao" :is-full-page="true"></loading>
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <main class="main-map">
      <div
        class="page-print"
        ref="documentprint"
        id="page-print"
        v-if="organizacao"
      >
        <div class="page-print-page1">
          <div class="page-print-header">
            <p class="page-print-header-title">{{ $t("map.map") }}</p>
            <p class="page-print-header-iniciative">
              {{ organizacao.organizationInitiative }}
              <span class="page-print-header-name">{{ organizacao.name }}</span>
            </p>
            <p class="page-print-header-pag">p 01/05</p>
          </div>
          <div class="page-print-page1-infos">
            <div class="page-print-page1-infos-orgimg">
              <img
                :src="
                  organizacao && organizacao.photoPath
                    ? organizacao.photoPath
                    : require('./../images/img-example.png')
                "
                alt
              />
            </div>
            <div class="page-print-page1-infos-org">
              <h2>{{ $t("map.title") }}</h2>
              <h3>{{ organizacao.organizationInitiative }}</h3>
              <span>{{ organizacao.name }}</span>
              <p>
                {{ $t("organization.areas." + organizacao.segment + ".text") }}
              </p>
              <p>{{ organizacao.city }}, {{ organizacao.country }}</p>
            </div>
            <div class="page-print-page1-infos-mission">
              <h2>{{ $t("map.mission") }}</h2>
              <h4>{{ organizacao.mission }}</h4>
            </div>
            <div class="page-print-page1-infos-img">
              <img src="./../images/card-icon1.svg" alt />
            </div>
          </div>
        </div>
        <div class="pagebreak"></div>

        <div class="page-print-page2">
          <div class="page-print-header">
            <p class="page-print-header-title">{{ $t("map.map") }}</p>
            <p class="page-print-header-iniciative">
              {{ organizacao.organizationInitiative }}
              <span class="page-print-header-name">{{ organizacao.name }}</span>
            </p>
            <p class="page-print-header-pag">p 02/05</p>
          </div>
          <div class="page-print-header2">
            <div class="page-print-header2-title">
              <h2>{{ $t("map.radar") }}</h2>
            </div>
            <div class="page-print-page2-line">
              <div class="page-print-page2-radar-graph">
                <div class="page-print-page2-radar-cards">
                  <div
                    class="page-print-page2-radar-card"
                    v-for="dados in radar"
                    v-bind:key="dados"
                    :style="{ 'background-color': dados.odsColor }"
                  >
                    <div class="page-print-page2-radar-text">
                      <p>{{ dados.ods }}</p>
                      <span>{{ $t("map.ods.ods" + dados.ods + ".name") }}</span>
                    </div>
                    <div class="page-print-page2-radar-img">
                      <img
                        :src="
                          require('./../images/ods-icon' + dados.ods + '.svg')
                        "
                      />
                      <img />
                    </div>
                  </div>
                </div>
              </div>
              <div class="page-print-page2-radar-chart">
                <img
                  class="mostrar-svg"
                  key="img-onu"
                  src="./../images/onu.svg"
                  alt
                />
                <canvas class="chart"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="page-print-footer">
          <img
            src="./../images/logo-seall.png"
            alt="Logo SEALL"
            class="img-logo"
          />
        </div>
        <div class="pagebreak"></div>

        <div class="page-print-page3">
          <div class="page-print-header">
            <p class="page-print-header-title">{{ $t("map.map") }}</p>
            <p class="page-print-header-iniciative">
              {{ organizacao.organizationInitiative }}
              <span class="page-print-header-name">{{ organizacao.name }}</span>
            </p>
            <p class="page-print-header-pag">p 03/05</p>
          </div>
          <div class="page-print-page3-ods">
            <div class="page-print-page3-ods-cards">
              <div
                class="page-print-page3-ods-card"
                v-for="(dados, index) in radar"
                v-bind:key="dados"
                v-bind:class="{
                  'page-print-page3-ods-card-breakdown': index === 9,
                }"
              >
                <div class="page-print-page3-ods-text">
                  <ods-icon :ods="dados.ods" :fill="'#707780'"></ods-icon>
                  <span>{{ dados.ods }}</span>
                  <h2>{{ $t("map.ods.ods" + dados.ods + ".name") }}</h2>
                  <p>{{ dados.finalNote }}%</p>
                </div>
                <div
                  :style="{
                    width: dados.finalNote + '%',
                    'background-color': '#e6e9ed',
                    height: '5px',
                  }"
                >
                  <div
                    class="page-print-page3-ods-bar"
                    :style="{
                      'background-color': dados.odsColor,
                      width: dados.finalNote,
                    }"
                  ></div>
                </div>
                <div class="page-print-page3-ods-desc">
                  <h2>{{ $t("map.ods.ods" + dados.ods + ".desc") }}</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="page-print-footer">
            <img
              src="./../images/logo-seall.png"
              alt="Logo SEALL"
              class="img-logo"
            />
          </div>
        </div>
        <div class="pagebreak"></div>

        <div class="page-print-page4">

          <div class="page-print-header">
            <p class="page-print-header-title">{{ $t("map.map") }}</p>
            <p class="page-print-header-iniciative">
              {{ organizacao.organizationInitiative }}
              <span class="page-print-header-name">{{ organizacao.name }}</span>
            </p>
            <p class="page-print-header-pag">p 04/05</p>
          </div>

          <div class="page-print-header4">
            <div class="page-print-header4-title">
              <h2>{{ $t("header.esg") }}</h2>
            </div>
            <div class="page-print-page4-line">
              <div class="page-print-page4-radar-graph">
                <div class="page-print-page4-radar-cards">
                  <div
                    class="page-print-page4-radar-card"
                    v-for="(axle, index) in esgLabels"
                    v-bind:key="axle"
                    :style="{ 'background-color': esgColors[index] }"
                  >
                    <div class="page-print-page4-radar-text">
                      <span>{{ axle }}</span>
                    </div>
                    <div class="page-print-page4-radar-img">
                      <img :src="require('./../images/' + esgIcons[index] + '.png')"/>
                      <img />
                    </div>
                  </div>
                </div>
              </div>
              <div class="page-print-page4-radar-chart">
                <img
                  class="mostrar-svg-esg"
                  src="../images/esg-icon.svg"
                />
                <canvas class="chart-esg" width="885" height="442"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="pagebreak"></div>

        <div class="page-print-page5">
          <div class="page-print-header">
            <p class="page-print-header-title">{{ $t("map.map") }}</p>
            <p class="page-print-header-iniciative">
              {{ organizacao.organizationInitiative }}
              <span class="page-print-header-name">{{ organizacao.name }}</span>
            </p>
            <p class="page-print-header-pag">p 05/05</p>
          </div>
          <div class="page-print-page5-esg">
            <div class="page-print-page5-esg-cards">
              <div
                class="page-print-page5-esg-card"
                v-for="(axle, index) in esgLabels"
                v-bind:key="axle"
                v-bind:class="{
                  'page-print-page5-esg-card-breakdown': index === 9,
                }"
              >
                <div class="page-print-page5-esg-text" :style="{'background-color': esgColors[index]}">
                  <div class="page-print-page4-radar-img">
                      <img :src="require('./../images/' + esgIcons[index] + '.png')" width="80"/>
                      <img />
                    </div>
                  <h2>{{ axle }}</h2>
                  <p>{{ esgDataIndividual[index].toFixed(1).toString().replace(".", ",") }}%</p>
                </div>
                <div class="page-print-page5-esg-desc">
                  <p v-html="$t('esg.' + axle + '.description')"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="page-print-footer">
            <img
              src="./../images/logo-seall.png"
              alt="Logo SEALL"
              class="img-logo"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import _ from "lodash";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import AuthService from "../../services/auth/auth";
import OrganizationService from "../../services/organization/organization.service.js";
import "../javascript/chart.bundle.min.js";
import "../javascript/chartjs-plugin-datalabels";
import "../javascript/chartjs-plugin-labels";
import {groupBy} from "lodash";
import SeallHeader from "./Header.vue";
import OdsIcon from "./OdsIcon.vue";
export default {
  name: "generated",
  props: {
    print: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      altura: "20vh",
      valor: 4.5,
      valor_map: 4.5,
      valor_graph: 500,
      index: 0,
      organizacao: null,
      mapa: {},
      radar: [],
      index: 0,
      chart: null,
      chart_esg: null,
      new_chart: false,
      mostrar: true,
      id: [],
      series: [],
      colors: [],
      loading: false,
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
      ods_onu_links: null,
      connects: [],
      current_stakeholder: "",
      colorsUsingForStakeholder: [],
      mapByStakeholder: [],
      ods_colors: [
        "#E5243B",
        "#DDA63A",
        "#4C9F38",
        "#C5192D",
        "#FF3A21",
        "#26BDE2",
        "#FCC30B",
        "#A21942",
        "#FD6925",
        "#DD1367",
        "#FD9D24",
        "#BF8B2E",
        "#3F7E44",
        "#0A97D9",
        "#56C02B",
        "#00689D",
        "#19486A",
      ],
      ods_number_colors: [
        { ods: 1, color: "#E5243B" },
        { ods: 2, color: "#DDA63A" },
        { ods: 3, color: "#4C9F38" },
        { ods: 4, color: "#C5192D" },
        { ods: 5, color: "#FF3A21" },
        { ods: 6, color: "#26BDE2" },
        { ods: 7, color: "#FCC30B" },
        { ods: 8, color: "#A21942" },
        { ods: 9, color: "#FD6925" },
        { ods: 10, color: "#DD1367" },
        { ods: 11, color: "#FD9D24" },
        { ods: 12, color: "#BF8B2E" },
        { ods: 13, color: "#3F7E44" },
        { ods: 14, color: "#0A97D9" },
        { ods: 15, color: "#56C02B" },
        { ods: 16, color: "#00689D" },
        { ods: 17, color: "#19486A" },
      ],
      mapByStakeholders: {},
      esgLabels: ["Ambiental", "Governança", "Social"],
      esgIcons: ["esg-ambiente", "esg-governanca", "esg-social"],
      esgColors: ["#00B7BB", "#6A11CA", "#E65F0C"],
      esgData: [],
      axles: [],
      esgDataIndividual: [],
      quiz: null,
    };
  },
  mounted() {
    this.loading = true;
    this.load();
    if (window.screen.availWidth < 1025) {
      this.valor = 1.25;
      this.valor_map = 1.5;
      this.valor_graph = 350;
    }
  },
  methods: {
    create_pdf() {
      if (this.print) {
        setTimeout(() => {
          window.print();
        }, 1000);
      }
    },
    mounted_chart(value) {
      this.loading = true;
      if (this.new_chart) {
        this.index = value;
        this.mostrar = false;
        this.loading = false;
      } else {
        this.new_chart = true;
        const self = this;

        setTimeout(() => {
          const labelImage = this.colors.map((color) => {
            const odsNumber = this.ods_number_colors.find(
              (ods) => ods.color === color
            ).ods;
            const baseUrl = process.env.VUE_APP_S3_BUCKET_ASSETS;


            const sizeIcon = window.screen.availWidth < 1025 ? 20 : 60;

            return {
              src: baseUrl + `ods-icon${odsNumber}.svg`,
              width: sizeIcon,
              height: sizeIcon,
            };
          });

          this.chart = new Chart(document.getElementsByClassName("chart"), {
            type: "doughnut",
            data: {
              datasets: [
                {
                  data: this.series,
                  fill: false,
                  backgroundColor: this.colors,
                  borderColor: this.colors,
                },
              ],
            },
            options: {
              plugins: {
                datalabels: {
                  anchor: "end",
                  align: "start",
                },
                labels: {
                  render: "image",
                  textMargin: -60,
                  images: labelImage,
                },
              },
              tooltips: { enabled: false },
              legend: { display: false },
              responsive: true,
              onClick: function (e, activeElement) {
                var bar = this.getElementAtEvent(e)[0];
                var index = bar._index;

                self.chart.fillStyle = self.colors[self.index];

                self.mostrar = false;
                self.index = index;
                self.new_chart = false;

                this.update();
              },
            },
          });
          this.loading = false;
          this.create_pdf();
        }, 500);
      }
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    load() {
      this.ods_onu_links = require("../json/ods_links.json");
      this.loading = true;

      OrganizationService.getStepOne(AuthService.getCogId())
        .then((response) => {
          this.organizacao = response.data.organization;
          this.radar = response.data.radar
            .filter((r) => r.finalNote > 0)
            .sort((a, b) => b.finalNote - a.finalNote);
          this.mapa = response.data.map;

          let sumNotes = 0;
          this.radar.forEach((x) => {
            sumNotes += x.finalNote;
          });

          this.radar.forEach((radar) => {
            radar.finalNote = parseFloat(
              (radar.finalNote / sumNotes) * 100
            ).toFixed(1);
            this.id.push(radar.ods);
            this.colors.push(radar.odsColor);
            this.series.push(parseFloat(radar.finalNote));
          });
          this.loading = false;
          this.mounted_chart();
        })
        .catch(() => {
          this.$notify({
            title: this.$t("main.error_main"),
            type: "error",
          });
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
          this.mounted_chart();
        });

        OrganizationService.getG4Quiz(AuthService.getCogId())
        .then((org) => {
          const questions = org.data.filter((q) => q.axle != "Conceitos e Relevância");
          this.quiz = groupBy(questions, (q) => q.axle);
          this.axles = this.esgLabels;
          this.calculateEsgData();
          this.generateChart();
        })
    },
    calculateEsgData() {
      let sumTotal = 0;
      const answerByAxle = [];
      this.axles.forEach((ax) => {
        const questions = this.quiz[ax];
        let sumRating = 0;
        questions.forEach((question) => {
          const answer = question.answer;
          const optionAnswer = question.options.find(
              (opt) => opt.id === answer.answerId
          );
          const ratingOfAnswer = optionAnswer ? optionAnswer.rating : 0;
          sumRating = sumRating + ratingOfAnswer;
        });
        sumTotal = sumTotal + sumRating;
        answerByAxle.push(sumRating);
        this.esgDataIndividual.push((sumRating * 100) / (5 * questions.length));
      });

      answerByAxle.forEach((a) => {
        this.esgData.push((a * 100) / sumTotal);
      });
    },
    generateChart() {
      const labelImage = [
        {
          src:
              process.env.VUE_APP_S3_BUCKET_ASSETS + 'esg-ambiente.png',
          width: 40,
          height: 40,
        },
        {
          src: process.env.VUE_APP_S3_BUCKET_ASSETS + 'esg-governanca.png',
          width: 40,
          height: 40,
        },
        {
          src: process.env.VUE_APP_S3_BUCKET_ASSETS + 'esg-social.png',
          width: 40,
          height: 40,
        },
      ];

      const self = this;

      setTimeout(() => {
        this.chart_esg = new Chart(document.getElementsByClassName("chart-esg"), {
          type: "doughnut",
          data: {
            labels: self.labels,
            datasets: [
              {
                data: this.esgData,
                fill: false,
                backgroundColor: self.esgColors,
                borderColor: self.esgColors,
              },
            ],
          },
          options: {
            plugins: {
              datalabels: {
                anchor: "end",
                align: "start",
              },
              labels: {
                render: "image",
                textMargin: -60,
                images: labelImage,
              },
            },
            tooltips: { enabled: false },
            legend: { display: false },
            responsive: false,
          },
        });
      }, 500);
    },
    getColors() {
      const hexColor = _.sample(this.ods_colors);
      if (this.colorsUsingForStakeholder.includes(hexColor)) {
        this.getColors();
      }
      this.colorsUsingForStakeholder.push(hexColor);
      return hexColor;
    },
    getColorFromStakeholder(stakeholder) {
      const connect = this.connects.find(
        (c) => c.stakeholder.value === stakeholder.value
      );
      if (connect) {
        return connect.color + " !important";
      }
      return null;
    },
    verifyConnections(item, direction = "from") {
      const connect = this.connects.find(
        (c) => c.stakeholder.value === this.current_stakeholder
      );

      const keyDirection = _.camelCase(direction + " key");
      if (connect.connections.length > 0) {
        return (
          connect.connections.filter(
            (connection) => connection[keyDirection] === item.key
          ).length > 0
        );
      }
      return false;
    },
    getCurrentColorFromCurrentStakeholder(forWho = null) {
      if (forWho) {
        const connect = this.connects.find(
          (c) =>
            c.stakeholder.value === this.current_stakeholder &&
            c.stakeholder.value === forWho
        );
        if (connect) {
          return connect.color;
        }
      } else {
        return (
          this.connects.find(
            (c) => c.stakeholder.value === this.current_stakeholder
          ).color + " !important"
        );
      }
    },
    getColorFromCurrentStakeholder(item, direction) {
      const connect = _.cloneDeep(
        this.connects.find(
          (c) => c.stakeholder.value === this.current_stakeholder
        )
      );
      return this.verifyConnections(item, direction)
        ? connect.color + " !important"
        : "";
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Loading,
    SeallHeader,
    OdsIcon,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: Lato-Regular;
  src: url(../fonts/Lato/Lato-Regular.ttf);
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  .noprint,
  .footer-map {
    display: none;
  }
}
@page {
  size: 42cm 29.7cm;
  margin: 0mm;
}

* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  color-adjust: exact !important; /*Firefox*/
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: #fff;
}

main {
  margin: 0vh 0vw;
  padding-top: 3vh;
}

.chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
  width: 100%;
  height: 100%;
  min-height: 443px;
  min-width: 866px;
  z-index: 10;
}

.checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  background: #fff;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, #d1d6ee);
}

.header-map {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 3vw;
}

.material-icons {
  color: #000000;
}

.main-map {
  background-color: #ffffff;
}

.footer-map {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.footer-map svg {
  width: 5%;
  height: 1vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw 2vh;
}

.footer-map rect {
  width: 100%;
  height: 1vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.div-map-footer-button {
  display: flex;
  flex-direction: row;
  padding: 5vh 0vw;
  justify-content: center;
}

.div-map-footer-button .material-icons {
  color: #2f1764;
}

.map-footer-button-back {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2f1764;
  background-color: #ffffff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 1vw;
  padding: 0vh 1vw;
}

.map-footer-button {
  cursor: pointer;
  color: #ffffff;
  background-color: #2f1764ff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 0vw 0vh 10vw;
  padding: 1vh 3vw;
}

.page-print {
  margin: 0vh 1vw;
}

.page-print-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0vh 0vw 3vh;
}

.page-print-header2 {
  border: 2px solid #a3a3a3;
  box-sizing: border-box;
  border-radius: 21.7826px;
  padding: 2rem;
  .page-print-header2-title {
    display: flex;
    justify-content: center;
  }
}

.page-print-header-title {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 3vh;
  line-height: 120%;
  text-align: center;
  color: #32363b;
  opacity: 0.8;
}

.page-print-header-iniciative {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 3vh;
  line-height: 120%;
  text-align: center;
  color: #533a8b;
}

.page-print-header-name {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 3vh;
  line-height: 120%;
  text-align: center;
  color: #32363b;
  opacity: 0.8;
}

.page-print-header-pag {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 3vh;
  line-height: 120%;
  text-align: center;
  color: #32363b;
  opacity: 0.8;
}

.page-print-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 2rem 2rem 2rem 5rem;
}

.page-print-footer img {
  width: 7%;
}

.page-print-page1-infos {
  display: flex;
  flex-direction: row;
}
.page-print-page1-infos-orgimg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15%;
}
.page-print-page1-infos-orgimg img {
  width: 90%;
  border-radius: 50%;
}

.page-print-page1-infos-org {
  padding: 0vh 0vw 0vh 3vw;
  width: 30%;
}

.page-print-page1-infos-mission {
  width: 35%;
}

.page-print-page1-infos-img {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.page-print-page1-infos-img img {
  width: 50%;
}

.page-print-page1 h3 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  color: #533a8b;
  margin: 0vh 0vw;
  padding: 5vh 0vw 0vh;
}

.page-print-page1-infos h2 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #687d9b;
  margin: 0vh 0vw;
  padding: 1vh 0vw;
}

.page-print-page1-infos h3 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  color: #533a8b;
  margin: 0vh 0vw;
  padding: 1vh 0vw;
}

.page-print-page1-infos h4 {
  font-family: NunitoSans-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 120%;
  color: #32363b;
  margin: 0vh 0vw;
  padding: 1vh 0vw;
}

.page-print-page1-infos span {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  color: #32363b;
  margin: 0vh 0vw;
  padding: 1vh 0vw;
}

.page-print-page1-infos p {
  margin: 0.5vh 0vw;
  padding: 0.5vh 0.5vw;
  background-color: #f7f9fa;
  border: 1px solid #533a8b;
  border-radius: 48px;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #533a8b;
  opacity: 1;
  width: 50%;
}

.page-print-page1-map {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5vh 0vh;
  margin: 0vh 0vw;
  width: 100%;
}

.page-print-page1-map i {
  border-radius: 50%;
}

.map-points-line {
  padding: 0.5vh 0.5vw;
  text-align: left;
  width: 12vw;
}

.map-print-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-height: 70%;
}

.map-print-card-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  background: #fff;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, #d1d6ee);
}

.map-print-card {
  border: 1px solid #533a8b;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: flex-start;
  background-color: #ffffff;
  margin: 0.5vh 0vw;
  padding: 0vh 0vw;
  width: 11vw;
}

.map-print-card input {
  border-radius: 50%;
}

.page-print-page1-map span {
  width: 100%;
  height: 7vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 170%;
  color: #533a8b;
}

.page-print-page1-map p {
  width: 90%;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 115%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0vh 1vw;
  text-align: center;
}

.page-print-page2 h2 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  color: #492e85;
}

.page-print-page2-line {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.page-print-page2-radar-graph {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
  border-radius: 16px;
  padding: 0vh 2vw;
  width: 50%;
}

.page-print-page2-radar-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  text-align: left;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}

.page-print-page2-radar-card {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1vh 0.5vw;
  margin: 0vh 0.5vw;
  height: 140px;
  border-radius: 10px;
  width: 140px;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.page-print-page2-radar-text {
  display: flex;
  flex-direction: row;
  height: 30%;
  width: 100%;
}

.page-print-page2-radar-card p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: flex-start;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 2.5vh;
  height: 100%;
}

.page-print-page2-radar-card span {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  justify-items: center;
  padding: 7px 0 0 7px;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-size: 1.2vh;
  text-transform: uppercase;
  width: 80%;
  height: 100%;
}

.page-print-page2-radar-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  height: 50%;
}

.page-print-page2-radar-img img {
  height: 100%;
}

.page-print-page2-radar-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.page-print-page-radar-chart-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
}

.mostrar-svg {
  position: absolute;
  z-index: 5;
}

.page-print-page3 {
  display: flex;
  flex-direction: column;
}

.page-print-page3-ods {
  border: 2px solid #a3a3a3;
  box-sizing: border-box;
  border-radius: 21.7826px;
}

.page-print-page3-ods-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.page-print-page3-ods-card {
  border-color: #ffff;
  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  margin: 2vh 1vw;
  width: 30%;
}

.page-print-page3-ods-card-breakdown {
  margin-top: 30vh;
}

.page-print-page3-ods-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f7f9fa;
  border-radius: 10px 10px 0px 0px;
  padding: 12px 16px 6px;
}

.page-print-page3-ods-text svg {
  padding: 0.5vh 1vw;
  width: 15%;
}

.page-print-page3-ods-text span {
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3vh;
  line-height: 120%;
  text-align: center;
  color: #707780;
  width: 7%;
}

.page-print-page3-ods-text h2 {
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2vh;
  line-height: 110%;
  color: #707780;
  width: 60%;
  margin: 0vh 0vw;
  padding: 0vh 0vw 0vh 1vw;
}

.page-print-page3-ods-text p {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 2.3vh;
  line-height: 120%;
  color: #707780;
  width: 18%;
  margin: 0vh 0vw;
  padding: 0vh 0vw 0vh 0.5vh;
}

.page-print-page3-ods-bar {
  height: 5px;
}

.page-print-page3-ods-desc h2 {
  border-color: #e6e9ed;
  border-radius: 0px 0px 10px 10px;
  border-style: solid;
  border-width: 3px;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 133%;
  text-align: center;
  color: #666666;
  margin: 0vh 0vw;
  padding: 1vh 2vw;
  min-height: 10vh;
}

.page-print-page4 h2 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  color: #492e85;
}

.page-print-page4-line {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.page-print-page4-radar-graph {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
  border-radius: 16px;
  padding: 0vh 2vw;
  width: 50%;
}

.page-print-page4-radar-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  text-align: left;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}

.page-print-page4-radar-card {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1vh 0.5vw;
  margin: 0vh 0.5vw;
  height: 140px;
  border-radius: 10px;
  width: 140px;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.page-print-page4-radar-text {
  display: flex;
  flex-direction: row;
  height: 30%;
  width: 100%;
}

.page-print-page4-radar-card p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: flex-start;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 2.5vh;
  height: 100%;
}

.page-print-page4-radar-card span {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  justify-items: center;
  padding: 7px 0 0 7px;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-size: 1.2vh;
  text-transform: uppercase;
  width: 80%;
  height: 100%;
}

.page-print-page4-radar-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  height: 50%;
}

.page-print-page4-radar-img img {
  height: 100%;
}

.page-print-page4-radar-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.page-print-header4 {
  border: 2px solid #a3a3a3;
  box-sizing: border-box;
  border-radius: 21.7826px;
  padding: 2rem;
  .page-print-header4-title {
    display: flex;
    justify-content: center;
  }
}

.page-print-page5-esg {
  border: 2px solid #a3a3a3;
  box-sizing: border-box;
  border-radius: 21.7826px;
}

.page-print-page5-esg-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.page-print-page5-esg-card {
  border-color: #ffff;
  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  margin: 2vh 1vw;
  width: 30%;
}

.page-print-page5-esg-card-breakdown {
  margin-top: 30vh;
}

.page-print-page5-esg-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f7f9fa;
  border-radius: 10px 10px 0px 0px;
  padding: 12px 16px 6px;
}

.page-print-page5-esg-text svg {
  padding: 0.5vh 1vw;
  width: 15%;
}

.page-print-page5-esg-text span {
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3vh;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  width: 7%;
}

.page-print-page5-esg-text h2 {
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2vh;
  line-height: 110%;
  color: #ffffff;
  width: 60%;
  margin: 0vh 0vw;
  padding: 0vh 0vw 0vh 1vw;
}

.page-print-page5-esg-text p {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 2.3vh;
  line-height: 120%;
  color: #ffffff;
  width: 18%;
  margin: 0vh 0vw;
  padding: 0vh 0vw 0vh 0.5vh;
}

.page-print-page5-esg-bar {
  height: 5px;
}

.page-print-page5-esg-desc p {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 133%;
  color: #666666;
  margin: 0vh 0vw;
  padding: 0vh 10px;
  min-height: 5vh;
}

.mostrar-svg-esg {
  position: absolute;
  z-index: 5;
  width: 10%;
  height: 10%;
}

@media screen and (max-width: 1025px) {
  html,
  body {
    border: 0vh 0vw;
    height: 10%;
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-map {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .div-map-header-links {
    text-decoration: none;
    padding: 3vh 0vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .div-map-header-photo {
    justify-content: center;
    width: 100%;
  }

  .div-map-header-photo img {
    border-radius: 100%;
    overflow: hidden;
    height: 10%;
    width: 10%;
  }

  .div-map-header-img img {
    width: 40vw;
  }

  .footer-map {
    margin: 0vh 0vw;
    padding: 0vh 0vw;
  }

  .div-map-footer-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1vh 0vw;
    margin: 0vh 0vw;
  }

  .div-map-footer-button .material-icons {
    color: #2f1764;
  }

  .mostrar-svg-esg {
    position: absolute;
    z-index: 5;
    width: 10%;
    height: 10%;
  }

  .map-footer-button-back {
    color: #2f1764;
    background-color: #ffffff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 1vh 0vw;
    padding: 0vh 1vw;
    width: 60vw;
  }

  .map-footer-button {
    color: #ffffff;
    background-color: #2f1764ff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 1vh 0vw;
    padding: 0.5vh 3vw;
    width: 75vw;
  }

  .mostrar-svg {
    position: absolute;
    z-index: 5;
    width: 20%;
  }
}
</style>